<template>
  <div class="wysiwyg">
    <p class="">可自行設定展間高度，最低為300cm，最高為900cm。</p>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.link {
  @apply text-sm text-gray-400;
}
</style>
